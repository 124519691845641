import NoData from 'components/NoData';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { pendingApprovalColumns, pendingInvoiceApprovalColumns } from 'utils/mockData';

import { Skeleton } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  fetchPendingApprovals,
  fetchSingleApproval,
  reviewMultiplePendingRequests,
  reviewRequest,
} from 'redux/actions/ApprovalAction';

import { AnalyticsDisplay } from 'pages/Requests';
import { fetchAllInvoices } from 'redux/actions/InvoiceAction';
import { fetchBills } from 'redux/actions/BillAction';

import {
  BankIcon,
  CalenderIcon,
  CheckIcon,
  SendIcon,
  SlashCircleIcon,
} from 'assets/icons';
import FloatingCta from 'components/BulkAction/FloatingCta';
import ConfirmDialog from 'components/ConfirmDialog';
import { ApprovalRequestStatusType } from 'components/FilterModal/FilterHelper';
import DeclineRequest from 'components/FundRequest/ReviewerModal/DeclineRequest';
import TopBar from 'components/TopBar';
import SchedulePayment from 'components/TransactionModal/SchedulePayment';
import { CustomSelectRadio } from 'components/UI/CustomSelectRadio';
import Loading from 'components/UI/Loading';
import CustomModal from 'components/UI/Modal';
import { toastError } from 'components/UI/toast';
import BatchPaymentDialog from 'pages/BatchPayment/BatchPaymentDialog';
import PendingOnboardingNotice from 'pages/Budgets/PendingOnboardingNotice';
import CollectionEmptyState from 'pages/Collections/CollectionEmptyState';
import BulkApprovalModal from 'pages/Transactions/components/BulkApprovalModal';
import TransactionEmptyStateData from 'pages/Transactions/transactionEmptystateData';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';
import { getBalances } from 'redux/actions/BudgetsAction';
import { createBulkPayment, payNowAction } from 'redux/actions/PaymentAction';
import { updateReimbursements } from 'redux/actions/ReimbursementsAction';
import {
  editBatchTransactionById,
  editTransactionById,
} from 'redux/actions/TransactionsAction';
import {
  RESET_BLOCK_APPROVAL,
  RESET_FLAGS_APPROVAL,
} from 'redux/reducers/ApprovalReducer';
import { RESET_BLOCK_REIMBURSEMENTS } from 'redux/reducers/ReimbursementsReducer';
import { SELECTED_TABLE_ROWS } from 'redux/reducers/TableReducer';
import { RESET_BLOCK_TRANSACTIONS } from 'redux/reducers/TransactionsReducer';
import { allPermissions, hasPermission } from 'utils/AllowedTo';
import {
  buildPendingApprovalTable,
  capitalizeFirstLetter,
  getAvailableBalance,
  getCurrency,
  groupSourceOptions,
} from 'utils/helper';
import { FEES } from 'utils/utility';
import Table from '../../components/Table';
import { approvalState } from './components/approvalState';
import PendingApprovalModal from './components/PendingApprovalModal';
import { QUERY_TYPE_MAP } from 'utils/utility';
import { useDebounce } from 'hooks/useDebounce';
import ModalComponent from 'components/UI/Modal/ModalComponent';
import ModalContainerWrap from 'components/UI/Modal/ModalContainerWrap';

const PendingApprovals = ({
  requestCode,
  handleSelect,
  onRowSelect,
  typeQuery = '',
  defaultStatus = 'pending',
  canView,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filterData, setFilterData] = useState([...ApprovalRequestStatusType]);
  const [selectTransaction, setSelectTransaction] = useState(null); //this was changed to null
  const [isOpen, setIsOpen] = useState(false);
  // const [isFiltered, setIsFiltered] = useState(false);
  const [filteredQuery, setFilteredQuery] = useState({ status: ['pending'] });
  const [openDeclineModal, setOpenDeclineModal] = useState(false);
  const [selectedRowInformation, setSelectedRowInformation] = useState(null);
  const [filteredSelectedRows, setFilteredSelectedRows] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectRequests, setSelectRequests] = useState([]);
  const [isSchedule, setIsSchedule] = useState(false);
  const [fees, setFees] = useState(0);
  const [reFetchData, setReFetchData] = useState(false);
  const [reSelectRows, setReSelectRows] = useState(false);
  const [fetchDataSuccess, setFetchDataSuccess] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [type, setType] = useState(null);
  const [source, setSource] = useState();
  const [action, setAction] = useState('');
  const [payNow, setPayNow] = useState(true);
  const [loadActions, setLoadActions] = useState(false);
  const [declineRequest, setDeclineRequest] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [additionalNote, setAdditionalNote] = useState('');
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 600);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [assetData, setAssetData] = useState(null);

  const [activeKeys, setActiveKeys] = useState([]);

  const { permissions } = allPermissions();

  const canEditTransaction = hasPermission({
    permissions,
    scopes: ['transaction-*', 'transaction-edit'],
  });

  const isFiltered = useRef(false);
  const { current: filtered } = isFiltered;

  const {
    fetchPendingApprovals: listPendingApprovals,
    reviewMultiplePendingRequests: {
      success: isMultipleReviewSuccess,
      loading: isMultipleReviewLoading,
    },
    reviewRequest: { loading: loadingReview, success: successReview },
    fetchSingleApproval: { data: singleApproval = {}, error },
    relatedApprovals: { data: relatedData },
  } = useSelector(({ approval }) => approval);

  const {
    updateReimbursement: { success: updatingSuccess },
  } = useSelector(({ reimbursement }) => reimbursement);

  const {
    editTransactionById: { success: isEditSuccess },
    editBatchTransactionById: { success: isEditSuccessBatch },
  } = useSelector(({ transaction }) => transaction);

  const {
    createPayment: { success },
    payNowAction: { loading: loadingPayNow, success: successPayNow },
    createBulkPayment: { success: paymentCompleted, loading: isPaying },
  } = useSelector(({ payments }) => payments);

  const {
    getBalances: { data: balances, loading: loadingBalances },
  } = useSelector(({ budgets }) => budgets);

  const { user: { data: user = {} } = {} } = useSelector(({ auth }) => auth);

  const activeTxCode = useRef(null);

  const {
    selectedTableRows: { selectedRows, type: rowType },
  } = useSelector(({ table }) => table);

  const {
    data: pendingApproval,
    success: pendingSuccess,
    loading: pendingLoading,
    error: pendingFailed,
    meta = {},
    summary = {},
  } = listPendingApprovals;
  const { page, total, hasMore, perPage } = meta;

  const {
    user: { data: userData },
  } = useSelector(({ auth }) => auth);

  const tableColumn = useMemo(
    () =>
      Object.values(QUERY_TYPE_MAP).includes(typeQuery)
        ? pendingInvoiceApprovalColumns
        : pendingApprovalColumns,
    [pendingApproval, typeQuery],
  );

  const handleRowClick = (row) => {
    if (row?.trigger === 'Batch')
      return history.push(`/transactions/batch/${row?.batchData?.code}`);
    dispatch({ type: RESET_BLOCK_APPROVAL, blockType: 'fetchSingleApproval' });
    activeTxCode.current = null;
    setIsOpen(true);
    setSelectTransaction(row);
  };

  useEffect(() => {
    if (debouncedValue) {
      filteredQuery.search = debouncedValue;
      dispatch(fetchPendingApprovals({ ...filteredQuery, search: debouncedValue }));
    }
    if (!debouncedValue && filtered) {
      delete filteredQuery.search;
      dispatch(fetchPendingApprovals({ ...filteredQuery }));
      isFiltered.current = Object.keys(filteredQuery).length ? true : false;
    }
    if (debouncedValue) isFiltered.current = true;
  }, [debouncedValue]);

  const rows = useMemo(
    () => buildPendingApprovalTable(pendingApproval),
    [pendingApproval],
  );

  useEffect(() => {
    if (success && canView) clearFilters();
  }, [success]);

  useEffect(() => {
    if (requestCode) {
      const element = rows.find(({ code }) => code === requestCode);
      setSelectTransaction(element);
      setIsOpen(true);
    }
    if (pendingSuccess) {
      setFetchDataSuccess(true);
      dispatch({ type: RESET_FLAGS_APPROVAL, blockType: 'fetchPendingApprovals' });
    } else {
      setFetchDataSuccess(false);
    }
  }, [pendingSuccess]);

  useEffect(() => {
    if (isMultipleReviewSuccess) {
      setIsSubmit(false);
      dispatch({ type: SELECTED_TABLE_ROWS, payload: { shouldClearRows: true } });
      dispatch(fetchPendingApprovals({ ...filteredQuery }));
      if (isMultipleReviewSuccess)
        dispatch({
          type: RESET_BLOCK_APPROVAL,
          blockType: 'reviewMultiplePendingRequests',
        });

      reloadExternalEntity();
    }
  }, [isMultipleReviewSuccess]);

  useEffect(() => {
    if (typeQuery) handleFilter({ status: defaultStatus, entity: typeQuery }, true);
  }, [typeQuery]);

  const reloadExternalEntity = () => {
    if (typeQuery === QUERY_TYPE_MAP.INVOICE) {
      dispatch(fetchAllInvoices());
    }

    if (typeQuery === QUERY_TYPE_MAP.BILL) {
      dispatch(fetchBills());
    }
  };

  const handleDataReload = () => {
    handleFilter({ entity: typeQuery });
    reloadExternalEntity();
  };

  const handleFilter = (query, isMount) => {
    if (reSelectRows) setReSelectRows(false);
    dispatch(fetchPendingApprovals({ ...query, ...filteredQuery }));
    setFilteredQuery({ ...query });
    isFiltered.current = isMount ? false : !!Object.keys(query).length;
  };

  const handleClick = (data = []) => {
    setActiveKeys(data);
    isFiltered.current = true;
    dispatch(fetchPendingApprovals({ status: data }));
  };

  const handlePagination = (page) => {
    isFiltered.current = true;

    dispatch(fetchPendingApprovals({ ...filteredQuery, perPage, page }));
    setFilteredQuery({ ...filteredQuery, perPage, page });
  };

  const clearFilters = () => {
    setActiveKeys([]);
    dispatch(
      fetchPendingApprovals({
        status: defaultStatus,
        ...(typeQuery && { entity: typeQuery }),
      }),
    );
  };

  useEffect(() => {
    if (!pendingLoading && reFetchData) setReFetchData(false);
  }, [pendingLoading]);

  useEffect(() => {
    if ((updatingSuccess || isEditSuccess) && !reFetchData) setReFetchData(true);
    setTimeout(() => {
      if (updatingSuccess)
        return dispatch({
          type: RESET_BLOCK_REIMBURSEMENTS,
          blockType: 'updateReimbursement',
        });
      if (isEditSuccess)
        return dispatch({
          type: RESET_BLOCK_TRANSACTIONS,
          blockType: 'editTransactionById',
        });
    }, 1000);
  }, [updatingSuccess, isEditSuccess]);

  const handleUpdateRequest = (code, field, value, approvalType) => {
    if (['reimbursement', 'reimbursements'].includes(approvalType)) {
      dispatch(
        updateReimbursements({
          code,
          [field]: value,
          showSuccessToast: false,
        }),
      );
    } else if (approvalType === 'batchTransactions') {
      dispatch(
        editBatchTransactionById({
          transaction_code: code,
          [field]: value,
          showSuccessToast: false,
          delay: 1000,
        }),
      );
    } else {
      dispatch(
        editTransactionById({
          [field]: value,
          transaction_code: code,
          showSuccessToast: false,
        }),
      );
    }
  };

  const actionHandler = (event, type, value) => {
    event?.stopPropagation();
    event?.preventDefault();
    setIsPopoverOpen(true);
    setSelectedOption(value);
    setType(type.toLowerCase());

    if (!['approve_schedule', 'decline_request'].includes(type))
      return setConfirmModal(true);
  };

  const closeModal = () => {
    setIsPopoverOpen(false);
    setConfirmModal(false);
  };

  useEffect(() => {
    if (successPayNow || successReview) {
      closeModal();
      isFiltered.current = true;

      dispatch(fetchPendingApprovals({ ...filteredQuery }));
      dispatch({ type: RESET_BLOCK_APPROVAL, blockType: 'fetchSingleApproval' });
      dispatch({ type: RESET_BLOCK_APPROVAL, blockType: 'relatedApprovals' });
      activeTxCode.current = null;
      setIsLoaded(true);
    }
  }, [successPayNow, successReview]);

  useEffect(() => {
    if (error) {
      setIsPopoverOpen(false);
      activeTxCode.current = null;
    }
  }, [error]);

  const handleConfirm = ({ schedule }) => {
    const code = selectedOption?.code;
    const approveCtaText = typeQuery === 'invoice' ? 'send' : 'pay';
    if (type === 'pay') {
      if (selectedOption?.source?.code) {
        return dispatch(payNowAction({ code }));
      } else if (!source) {
        return toastError('Select a source');
      }
      return dispatch(payNowAction({ code, source: source.value }));
    } else if (
      [`approve and ${approveCtaText}`, 'approve', 'approve_schedule'].includes(type)
    ) {
      handleApproveRequest({
        schedule,
        code: selectedOption?.code,
      });
    }
  };

  const handleApproveRequest = ({ schedule, code }) => {
    dispatch(
      reviewRequest({
        code,
        status: 'approved',
        actionLater: schedule ? undefined : !payNow,
        schedule,
      }),
    );
  };

  useEffect(() => {
    if (singleApproval?.code) setLoadActions(true);
  }, [singleApproval?.code]);

  const fetchSingleData = useCallback(
    (data) => {
      const { code = '' } = data?.approvalData || {};

      activeTxCode.current = code;
      if (activeTxCode.current) dispatch(fetchSingleApproval(code));
    },
    [activeTxCode.current],
  );

  const Actions = useCallback(
    ({ list: selectedData }) => {
      const status = selectedData?.status?.value.toLowerCase();

      useMemo(() => {
        if (selectedData?.approvalData?.code !== activeTxCode.current) {
          setLoadActions(false);
          if (['success', 'processed', 'pending'].includes(status))
            fetchSingleData(selectedData);
        }
      }, []);

      const { canApprovePay, yourTurnToApprove } = approvalState({
        approvalData: singleApproval,
        relatedData,
        user,
      });

      if (!loadActions && ['pending'].includes(status))
        return (
          <div className="p-2 export-wrap" style={{ transform: 'translateX(0%)' }}>
            <div className="spinner-3 mx-auto" style={{ width: '30px' }}></div>
          </div>
        );

      const approveCtaText = typeQuery === 'invoice' ? 'send' : 'pay';

      return (
        <div className="actions-dialog">
          {!yourTurnToApprove && (
            <div
              className="actionLink"
              onClick={(event) => {
                setIsPopoverOpen(false);
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              No options available
            </div>
          )}

          {loadActions && yourTurnToApprove && (
            <>
              {canApprovePay && (
                <div
                  className="actionLink"
                  onClick={(event) => {
                    setPayNow(canApprovePay);
                    actionHandler(event, `approve and ${approveCtaText}`, singleApproval);
                  }}
                >
                  <SendIcon stroke="#79716B" width={16} height={16} className="mr-4" />
                  Approve and {approveCtaText}
                </div>
              )}

              {typeQuery !== 'invoice' && (
                <>
                  {' '}
                  <div
                    className="actionLink"
                    onClick={(event) => {
                      setPayNow(false);
                      actionHandler(event, 'approve', singleApproval);
                    }}
                  >
                    <CheckIcon stroke="#79716B" width={16} height={16} className="mr-4" />
                    Approve only
                  </div>
                  {canApprovePay && (
                    <div
                      className="actionLink"
                      onClick={(event) => {
                        setIsSchedule(true);
                        actionHandler(event, 'approve_schedule', singleApproval);
                      }}
                    >
                      <CalenderIcon
                        stroke="#79716B"
                        width={16}
                        height={16}
                        className="mr-4"
                      />
                      Approve and schedule
                    </div>
                  )}
                </>
              )}

              <div
                className="actionLink"
                onClick={(event) => {
                  setDeclineRequest(true);
                  setIsLoaded(false);
                  actionHandler(event, 'decline_request', singleApproval);
                }}
              >
                <SlashCircleIcon
                  stroke="#79716B"
                  width={16}
                  height={16}
                  className="mr-4"
                />
                Decline request
              </div>
            </>
          )}
        </div>
      );
    },
    [singleApproval?.code, loadActions],
  );

  useEffect(() => {
    if (!balances?.budgets?.length || !balances?.balances?.length)
      dispatch(getBalances());
  }, []);

  const [budgetList, setBudgetList] = useState([]);

  useEffect(() => {
    if (balances?.budgets?.length || balances?.balances?.length) {
      const budget = getAvailableBalance(balances?.budgets, 'budgets');
      const available_balance = getAvailableBalance(balances?.balances, 32, false, true);
      setBudgetList((prevOptions) => [...available_balance].concat([...budget]));
    }
  }, [balances?.budgets?.length, balances?.balances?.length]);

  async function loadOptions(search, loadedOptions, { page }) {
    let filteredOption = budgetList.filter((item) =>
      item?.name?.toLowerCase().includes(search?.toLowerCase()),
    );

    return {
      options: groupSourceOptions(filteredOption),
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  }

  const getTransactionType = (type) => {
    const typeMap = {
      transactions: 'transaction',
      reimbursements: 'reimbursement',
      payments: 'transaction',
      batch: 'batchTransactions',
    };

    return typeMap[type] || type;
  };

  const calculateSum = (transactionList) => {
    const totalSum = transactionList.reduce(
      (accumulator, currentValue) => accumulator + (currentValue?.amount ?? 0),
      0,
    );

    return totalSum / 100;
  };

  const transformSelectedRows = (transactionList = []) => {
    return transactionList.map((opt) => {
      const type = opt?.transactionType?.name?.toLowerCase();
      let body = getTransactionType(type);

      return {
        approvalCode: opt?.code,
        code: opt[body]?.code,
        user:
          opt[body]?.user || opt?.batchTransactions?.payer
            ? {
                firstName:
                  body === 'batchTransactions'
                    ? `${opt?.batchTransactions?.payer?.firstName}`
                    : `${opt[body]?.user?.firstName}`,
                lastName:
                  body === 'batchTransactions'
                    ? `${opt?.batchTransactions?.payer?.lastName}`
                    : `${opt[body]?.user?.lastName}`,
              }
            : null,
        amount: opt[body]?.amount,
        vendor: opt[body]?.recipient
          ? { name: opt[body]?.recipient?.name }
          : opt[body]?.vendor
          ? { name: opt[body]?.vendor?.name }
          : null,
        type: ['reimbursement', 'transaction'].includes(body) ? 'PAYMENT' : body,
        source: opt?.source?.code
          ? {
              code: opt?.source?.code,
              name: opt?.source?.name,
            }
          : null,
        approvalType: body,
        currency: opt[body]?.currency,
        category:
          type === 'batchTransactions'
            ? null
            : opt[body]?.category?.code
            ? {
                code: opt[body]?.category?.code,
                name: opt[body]?.category?.name,
              }
            : null,
      };
    });
  };

  useEffect(() => {
    if (rowType === 'requests') {
      let totalAllSum = calculateSum(transformSelectedRows(selectedRows));

      const filteredPendingRows = selectedRows.filter(
        ({ status: { value: rowStatus } }) => 'pending' === rowStatus,
      );
      const filteredApprovedRows = selectedRows.filter(
        ({ status: { value: rowStatus } }) => 'approved' === rowStatus,
      );

      setSelectedRowInformation({
        all: {
          count: selectedRows.length,
          sum: totalAllSum,
        },
        pending: {
          count: filteredPendingRows?.length,
        },
        approved: {
          count: filteredApprovedRows?.length,
        },
      });

      setFilteredSelectedRows({
        pending: transformSelectedRows(filteredPendingRows),
        approved: transformSelectedRows(filteredApprovedRows),
      });
    }
  }, [selectedRows.length]);

  const handlePay = () => {
    setReFetchData(false);

    if (['decline', 'approve'].includes(action)) {
      if (action === 'decline' && !additionalNote)
        return toastError('Please input reason for decline');

      const payload = {
        requests: filteredSelectedRows?.['pending']?.map(
          ({ approvalCode: code = '' }) => code,
        ),
        actionLater: true,
        status: action === 'decline' ? 'declined' : 'approved',
        ...(additionalNote && { reason: additionalNote }),
      };

      dispatch(reviewMultiplePendingRequests(payload));
    } else if (action === 'pay') {
      let hasNoSource = 0;
      const payload = {
        items: filteredSelectedRows?.['approved']?.map(({ code = '', source }) => {
          if (!source) hasNoSource += 1;
          return {
            code,
            source: source?.code,
          };
        }),
      };
      if (hasNoSource > 0)
        return toastError(`${hasNoSource} of the selected items has no source`);
      dispatch(createBulkPayment(payload));
    }
  };

  const handleAction = (data) => {
    const statusType = ['decline', 'approve'].includes(data) ? 'pending' : 'approved';

    if (['decline', 'approve', 'pay'].includes(data)) {
      setTotalAmount(calculateSum(filteredSelectedRows[statusType]) ?? 0);
      setFees(
        data === 'decline'
          ? 0
          : (selectedRowInformation?.[statusType]?.count ?? 0) * FEES,
      );
    }

    if (data === 'approve') {
      setFetchDataSuccess(false);
      setReSelectRows(false);
    }

    setAction(data);

    setIsSubmit(true);
  };

  const show = !!pendingApproval?.length || (filtered && !pendingApproval?.length);

  const loadingState = pendingLoading && !filtered && !reFetchData;

  if (loadingState)
    return (
      <section>
        <div className="mb-3">
          <AnalyticsDisplay loading />
        </div>

        <div className="d-flex">
          <Skeleton.Button
            active
            shape="square"
            style={{ borderRadius: 8, height: '40px', width: '16rem' }}
          />
          <div className="ms-auto">
            <Skeleton.Button
              active
              shape="square"
              style={{ borderRadius: 8, height: '40px', width: '7rem' }}
            />
          </div>
        </div>

        <div className="mt-4">
          <Table columns={tableColumn} data={rows} hasCheckBox={false} loading />
        </div>
      </section>
    );

  return (
    <>
      <PendingOnboardingNotice />

      {!!pendingApproval?.length && (
        <AnalyticsDisplay
          data={summary}
          activeKeys={activeKeys}
          handleClick={handleClick}
        />
      )}

      <div>
        <TopBar
          showBarSearch={true}
          showFilter={true}
          searchVal={search}
          setSearchVal={setSearch}
          withOutSearch
          filterData={filterData}
          handleFilterSelect={(updateVal) => {
            setFilterData(updateVal);
          }}
          handleFilterApply={handleFilter}
          clearFilters={clearFilters}
          inputPlaceholder="Search"
        />
      </div>

      {!pendingApproval?.length && !loadingState ? (
        <div>
          {filtered ? (
            <div className="tabinnerWrapper">
              <NoData
                headerText="No Pending Approvals for this filter yet"
                bodyText="All your pending approvals would be listed here"
                buttonLabel="Clear filter"
                onClickHandler={clearFilters}
                withButton={true}
              />
            </div>
          ) : (
            <>
              {typeQuery === 'invoice' ? (
                <CollectionEmptyState
                  onClickHandler={() => history.push('/receivables/invoices/create')}
                  handleSelect={handleSelect}
                />
              ) : (
                <TransactionEmptyStateData handleSelect={handleSelect} />
              )}
            </>
          )}
        </div>
      ) : (
        <>
          <Container className="px-0 ">
            <Row className="pt-4 pb-3">
              <Col xs={12}>
                <Table
                  columns={tableColumn}
                  data={rows}
                  pagination
                  onRowClick={handleRowClick}
                  hasMore={hasMore}
                  onRowSelect={onRowSelect}
                  hasCheckBox={loadingState ? false : true}
                  currentPage={page}
                  nextPage={() => handlePagination(page + 1)}
                  previousPage={() => handlePagination(page - 1)}
                  totalPage={Math.ceil(total / perPage)}
                  reSelectRows={reSelectRows}
                  fetchDataSuccess={fetchDataSuccess}
                  setReSelectRows={setReSelectRows}
                  popoverAction={Actions}
                  popoverState={isPopoverOpen}
                  setPopoverState={setIsPopoverOpen}
                  loading={loadingState}
                />
              </Col>
            </Row>
            {selectedRows.length > 0 && rowType === 'requests' && (
              <FloatingCta
                selectedRowInformation={selectedRowInformation}
                selectedRows={selectedRows}
                handleAction={handleAction}
                hasPayment={true}
              />
            )}
          </Container>

          <Modal show={confirmModal} centered dialogClassName="custom-dialog">
            <ConfirmDialog
              title={`${capitalizeFirstLetter(type)} ${typeQuery || 'transaction'}`}
              subTitle={`Are you sure you want to ${type} this ${
                typeQuery || 'transaction'
              }?`}
              onConfirm={handleConfirm}
              onCancel={closeModal}
              isDeleteDialog={type === 'cancel'}
              extraChild={
                !selectedOption?.source?.code &&
                typeQuery !== 'invoice' && (
                  <div className="border-top" style={{ maxWidth: '300px' }}>
                    <Row className="align-items-center">
                      <CustomSelectRadio
                        label="Where are you paying from?"
                        name="source"
                        placeholder="Select a source"
                        onChange={(val) => {
                          handleUpdateRequest(
                            selectedOption?.reimbursement?.code ??
                              selectedOption?.batchTransactions?.code ??
                              selectedOption?.transaction?.code,
                            'source',
                            val.value,
                            selectedOption?.trigger?.toLowerCase(),
                          );
                        }}
                        value={source}
                        isLoading={loadingBalances}
                        isDisabled={loadingBalances}
                        loadOptions={loadOptions}
                      />
                    </Row>
                  </div>
                )
              }
              actionBtnText="Confirm"
              loading={loadingPayNow || loadingReview}
            />
          </Modal>

          <Modal show={isSchedule} centered dialogClassName="custom-dialog">
            <SchedulePayment
              onClose={() => {
                setIsSchedule(false);
              }}
              loading={loadingReview}
              setSchedule={handleConfirm}
              // handleCreate={onHandleSubmit}
            />
          </Modal>

          <ModalComponent active={!!declineRequest} scroll={false}>
            <ModalContainerWrap
              isWhite
              modalName="Decline request"
              onClose={() => {
                setDeclineRequest(false);
              }}
            >
              <DeclineRequest
                onClose={() => {
                  setDeclineRequest(false);
                }}
                approvalCode={selectedOption?.code}
                userCode={user?.user?.code}
                isLoaded={isLoaded}
                kebabAction
                hideTitle
              />
            </ModalContainerWrap>
          </ModalComponent>

          {/* <PendingApprovalModal
            typeQuery={typeQuery}
            isOpen={isOpen}
            data={selectTransaction}
            setData={setSelectTransaction}
            userCode={userData?.user?.code}
            closeModal={() => setIsOpen(false)}
            reloadData={handleDataReload}
          /> */}

          {selectedRows.length > 0 && rowType === 'requests' && (
            <>
              {action === 'approve' ? (
                <BulkApprovalModal
                  openModal={isSubmit}
                  columns={['Requested by', 'Amount', 'Recipient', 'Category', 'Source']}
                  rows={filteredSelectedRows?.['pending']}
                  isLoading={isMultipleReviewLoading}
                  onCancel={() => {
                    if (reFetchData) {
                      setReSelectRows(true);
                      dispatch(fetchPendingApprovals({ ...filteredQuery }));
                    }
                    setIsSubmit(false);
                  }}
                  handleUpdate={handleUpdateRequest}
                  tab={'pending request'}
                  onConfirm={handlePay}
                  isSuccess={updatingSuccess || isEditSuccess || isEditSuccessBatch}
                  amount={
                    <CurrencyFormat
                      prefix={getCurrency('NGN')}
                      value={totalAmount.toFixed(2)}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  }
                  fee={
                    <CurrencyFormat
                      prefix={getCurrency('NGN')}
                      value={fees.toFixed(2)}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  }
                  title={'approval'}
                  total={
                    <CurrencyFormat
                      prefix={getCurrency('NGN')}
                      value={(totalAmount + fees).toFixed(2)}
                      displayType="text"
                      thousandSeparator={true}
                    />
                  }
                />
              ) : (
                <Modal
                  show={isSubmit}
                  centered
                  dialogClassName="custom-dialog batch-payment-confirm"
                >
                  <BatchPaymentDialog
                    disabled={isMultipleReviewLoading}
                    onClick={handlePay}
                    onCancel={() => {
                      setIsSubmit(!isSubmit);
                      setAdditionalNote('');
                    }}
                    onNoteChanged={setAdditionalNote}
                    note={additionalNote}
                    amount={
                      <CurrencyFormat
                        prefix={getCurrency('NGN')}
                        value={totalAmount.toFixed(2)}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    }
                    description={
                      <>
                        You are about to {action}{' '}
                        <span className="fw-bolder">
                          {action === 'decline'
                            ? selectedRowInformation?.['pending']?.count
                            : action === 'pay'
                            ? selectedRowInformation?.['approved']?.count
                            : '0'}{' '}
                          requests.
                        </span>
                      </>
                    }
                    fee={
                      <CurrencyFormat
                        prefix={getCurrency('NGN')}
                        value={fees.toFixed(2)}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    }
                    icon={<BankIcon width="24" height="24" />}
                    title={`Please confirm your ${
                      action === 'pay' ? 'payment' : 'decline'
                    }.`}
                    total={
                      <CurrencyFormat
                        prefix={getCurrency('NGN')}
                        value={(totalAmount + fees).toFixed(2)}
                        displayType="text"
                        thousandSeparator={true}
                      />
                    }
                    loading={isMultipleReviewLoading || isPaying}
                    addNote={action === 'decline'}
                  />
                </Modal>
              )}
            </>
          )}
        </>
      )}

      <ModalComponent
        active={!!selectTransaction}
        selectedAsset={selectedAsset}
        assetData={assetData}
        setSelectedAsset={setSelectedAsset}
        singleData={selectTransaction?.transactionData}
        scroll={false}
      >
        <PendingApprovalModal
          setAssetData={setAssetData}
          setSelectedAsset={setSelectedAsset}
          typeQuery={typeQuery}
          isOpen={isOpen}
          data={selectTransaction}
          setData={setSelectTransaction}
          userCode={userData?.user?.code}
          closeModal={() => setIsOpen(false)}
          reloadData={handleDataReload}
        />
      </ModalComponent>
    </>
  );
};

export default PendingApprovals;
